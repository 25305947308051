import React, { useState } from 'react';
import './FaqModal.css';
import { solarFaqData, InsuranceFaqData } from '../utils/Faq'; 
import config from "../config.json";

const FaqModal = ({ isOpen, setIsOpen }) => {
  const toggleModal = () => {
    setIsOpen(false);
  };

  var faqData = {};

  faqData = config.appName == "insurance" ? InsuranceFaqData : solarFaqData;
   

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-6 rounded-lg lg:w-[50%] w-[90%] shadow-lg px-10 h-[90vh] overflow-y-scroll">
            <span
              className="absolute top-4 right-4 text-gray-500 text-2xl font-bold cursor-pointer hover:text-black"
              onClick={toggleModal}
            >
              &times;
            </span>
            <div className="mt-10 px-10">
              <h2 className="text-2xl font-semibold mb-4">
                {config.appName == "insurance" ? 'Insurance FAQ' : 'Solar FAQ'}
              </h2>

              <div className="text-left mt-10">
                <h3 className="mb-3 underline">Pricing</h3>
                <ol className="flex flex-col gap-3 list-decimal ml-5 font-medium">
                  {faqData.pricing.map((item, index) => (
                    <li key={index}>
                      Maximum loan amount – {item.maximumLoanAmount}
                    </li>
                  ))}
                  <li>Maximum Tenor – {faqData.pricing[0].maximumTenor}</li>
                  <li>Interest rate – {faqData.pricing[0].interestRate}</li>
                  {config.appName == "insurance" ? (
                      <li>Other fees – {faqData.pricing[0].otherFees}</li>
                  ) : (
                     <>
                       <li>Management fee – {faqData.pricing[0].managementFee}</li>
                       <li>Insurance fee – {faqData.pricing[0].insuranceFee}</li>
                     </>
                  )}
                </ol>
              </div>

              <div className="faq-section mb-10">
                <h3>Steps</h3>
                <ol className="text-left list-decimal flex flex-col gap-4">
                  {faqData.steps.map((step, index) => (
                    <li key={index}>{step.description}</li>
                  ))}
                </ol>
              </div>

              {config.appName == "insurance" && faqData.repayment && (
                <div className="faq-section text-left mt-10">
                  <h3>Repayment</h3>
                  <ol className="list-decimal flex flex-col gap-4">
                    {faqData.repayment.map((point, index) => (
                      <li key={index}>{point.description}</li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqModal;
